
import React, { Component, useRef } from 'react';
import ReactDOM from 'react-dom';
import Select from 'react-select';

const EdicionesSidebarHeader = (props) => {
    return (
        <div className="ediciones-sidebar-header">
            <span>{props.textos.prox_edic_en_txt} </span>
            <div className="select-ubicaciones-sid-wrap">
                <Select
                    options={props.ubicaciones}
                    value={props.selectedUbicacion}
                    onChange={props.hanldeSelectUbicaciones}
                    isClearable={true}
                />
            </div>    
        </div>
    )
}

const decodeHTMLEntities = (html) => {
    var txt = document.createElement('textarea');
	txt.innerHTML = html;
	return txt.value;
  }

const EdicionesList = (props) => {
    return (
        <div className="ediciones-sidebar-list">
            { props.edicionesList.length > 0 && props.edicionesList.map( ( edicion, index) => {
                return (
                    <div key={`sidebar-edicion-${index}`} className="edicion-sidebar-item">
                        <div className="edicion-sidebar-item-container">
                            <div className="edicion-sidebar-row top mb-2">
                                <div className="edicion-ubicacion-wrap-sidebar">
                                    <strong className="edicion-sidebar-name">{edicion.ubicacion.name}</strong>
                                    <div className="edicion-sidebar-fechas mb-2">{edicion.fechaInicioFormated}</div>
                                </div>
                                { props.tipo == 'compra' && edicion.precio.text != '' &&
                                    <div className="precio-matricula-wrap-sidebar">
                                        <span className="edicion-sidear-price">
                                            {edicion.precio.text} {edicion.precio.currency}
                                            { edicion.atributosDiferentes.show == true && 
                                            <span className="ml-1">*</span> }
                                        </span>
                                        { edicion.precioMatricula.text != '' && edicion.precioMatricula.text != '0.00' &&  
                                            <span className="edicion-sidebar-matricula">{props.textos.matricula_txt} {edicion.precioMatricula.text} {edicion.precioMatricula.currency}</span>
                                        }    
                                    </div>
                                }    
                            </div>
                            { 
                                // <h3 className="">{ edicion.title}</h3>
                            }
                            
                            <div className="edicion-sidebar-row bottom mb-2 font-italic">
                                <span className="edicion-sidebar-modalidad">{props.textos.modalidad_txt} {edicion.modalidad.label}</span>
                                <span className="edicion-sidebar-formato">{props.textos.formato_txt} {edicion.formato}</span>
                            </div>
                            <div className="edicion-sidebar-row mb-2">
                                { 
                                    //<span className="edicion-sidebar-precio-type">{edicion.precio.text}</span>
                                }
                            </div>
                            <div className={`sidebar-edicion-button ${props.tipo} ${edicion.presentacionPDF != '' ? 'has-pdf' : ''}`}>

                                { /* props.tipo == 'compra' && edicion.mensajeEstado != '' && edicion.estado != '2' &&  
                                    <span className="color-lila font-size-18 py-1">{edicion.mensajeEstado}</span> */ }
                                
                                { props.tipo == 'compra' && edicion.presentacionPDF != '' && 
                                    <a href={edicion.presentacionPDF} className="font-size-25 mr-4" target="_blank">
                                        <i className="color-lila fas fa-file-pdf"></i>
                                    </a>
                                }

                                { props.tipo == 'compra' && edicion.botones['botonCompraFicha'].show == true &&
                                    <div className="boton-sidebar-compra-wrap">
                                        <a href={edicion.botones['botonCompraFicha'].url} className="width-100 btn btn-primary btn-block btn-rounded-50 font-size-16">{props.textos.comprar_txt}</a>
                                        { edicion.atributosDiferentes.show == true && 
                                            <span className="texto-sidebar-diff-attr">{edicion.atributosDiferentes.texto}</span> }
                                    </div>
                                }

                                { props.tipo == 'compra' && edicion.botones['botonCompraFicha'].showMensaje == true &&
                                    <div className="boton-sidebar-compra-wrap">
                                        <span class="mensaje-estado-edicion-ig">{edicion.botones['botonCompraFicha'].mensaje}</span>
                                    </div>
                                }

                                { props.tipo == 'compra' && edicion.botones['botonInscribeteFicha'].show == true &&
                                    <a href="#" 
                                        className="form-overlay-button boton-inscr-compra-dossier btn btn-primary btn-block btn-rounded-50 font-size-16"
                                        data-target={edicion.botones['botonInscribeteFicha'].targetElement}
                                        data-edicion-id-ig-Web={`${edicion.edicionIdIgWeb}`}
                                        data-edicion-id-ig-plus={`${edicion.edicionIdIgPlus}`}
                                        data-active-campaign={`${edicion.tagsActiveCampaign}`}>
                                            {edicion.botones['botonInscribeteFicha'].label}</a>
                                }

                                { props.tipo == 'compra' && edicion.botones['botonInscribeteFicha'].showMensaje == true &&
                                    <div className="boton-sidebar-compra-wrap">
                                        <span class="mensaje-estado-edicion-ig">{edicion.botones['botonInscribeteFicha'].mensaje}</span>
                                    </div>
                                }

                                { /* edicion.mensajeEstado != '' &&
                                    <span className="">{ edicion.mensajeEstado }</span> */
                                }

                                { props.tipo == 'dossier' && 
                                    <a href="#" 
                                        className="boton-inscr-compra-dossier btn btn-primary btn-block btn-rounded-50 font-size-16 form-overlay-button ml-2" 
                                        data-target={`${edicion.botones['botonSolicitaInfo'].targetElement}`}
                                        data-edicion-id-ig-Web={`${edicion.edicionIdIgWeb}`}
                                        data-edicion-id-ig-plus={`${edicion.edicionIdIgPlus}`}
                                        data-active-campaign={`${edicion.tagsActiveCampaign}`}>
                                            {edicion.botones['botonSolicitaInfo'].label}</a>
                                }


                            </div>
                        </div>    
                    </div>
                )
            })}
        </div>
    )
}

export default class SidebarEdicionesComponent extends Component{
    constructor(...args){
        super(...args);
        this.state={
            ediciones: {},
            cursosInteresantes: [],
            ubicaciones: [],
            isLoading: true,
            selectedUbicacion: null
        }
        this.getubicaciones = this.getubicaciones.bind(this);
        this.hanldeSelectUbicaciones = this.hanldeSelectUbicaciones.bind(this);
    }

    hanldeSelectUbicaciones( selectedUbicacion ){
        this.setState({selectedUbicacion: selectedUbicacion});
    }

    getubicaciones(edicionesList) {

        let ubicaciones = [];
        
        edicionesList.forEach( edicion => {
            let ubicacionExists = ubicaciones.find( ubicacion => ubicacion.id == edicion.ubicacion.id);
            if ( ubicacionExists == undefined ) {
                ubicaciones.push(edicion.ubicacion);
            }            
        });
        return ubicaciones;
    }

    componentDidMount(){
    
        let cursoActiveCampaignTag = document.getElementById('active-campaign-curso-tags');
        let activeCampaignTag = false;
        
        if ( cursoActiveCampaignTag != null ) {

            activeCampaignTag = cursoActiveCampaignTag.getAttribute('data-cursotags');
        
            if ( activeCampaignTag != undefined && activeCampaignTag != false ) {
                activeCampaignTag = activeCampaignTag;
            } else {
                activeCampaignTag = false;
            }    

        } 

        this.setState({ 
            isLoading: true,
            tipo: this.props.tipo,
            activeCampaignTag: activeCampaignTag 
        });
        
        const urlToFetch = this.props.urlProximasEdidiones;
        
        if ( urlToFetch ) {
    
            fetch(`${urlToFetch}?curso_id=${this.props.cursoId}&curso_id_ig_plus=${this.props.cursoIdIgPlus}&ciudad=`, {
                method: 'get',
                headers: this.props.headers,
                credentials: 'same-origin'
            })
            .then(response => {
                return response.ok ? response.json() : 'Not Found...';
            }).then(json_response => {

                // console.log(json_response);
                if (typeof json_response === 'object') {
                    this.setState({ ediciones: json_response, ubicaciones: this.getubicaciones(json_response), isLoading: false })
                } else {
                    this.setState({ediciones: false, isLoading: false});
                     
                    
                    fetch(`${this.props.urlCursosTePuedenInteresar}?curso_id=${this.props.cursoId}`, {
                        method: 'get',
                        headers: this.props.headers,
                        credentials: 'same-origin'
                    })
                    .then(response => {
                        return response.ok ? response.json() : 'Not Found...';
                    }).then(json_response => {
                        console.log('esto recibe de interes')
                        console.log(json_response)
                        if (typeof json_response === 'object') {
                            this.setState({ cursosInteresantes: json_response, isLoading: false, ediciones: false })
                        } else {
                            this.setState({ediciones: false, isLoading: false});                                                
                        }

                    });





                }
            });
    
        }
        
      
      }



    render(){
        return(
            <div className="ediciones-component-void w-100">

                { this.state.isLoading && <span>Loading</span> }

                { !this.state.isLoading && this.state.ediciones == false && 
                    <>

                        { this.state.activeCampaignTag == false && 
                            this.state.cursosInteresantes.hasOwnProperty('cursos_interesantes') && 
                            this.state.cursosInteresantes.cursos_interesantes.length == 0 &&
                            <div className="ediciones-sidebar-header">
                                <span>{this.props.textos.se_abriran_prox_txt}</span>
                            </div>
                        }
                        { this.state.activeCampaignTag != false && 
                            <div className="ediciones-sidebar-header">
                                <span>{this.props.textos.se_abriran_prox_solicita_info_txt}</span>
                            </div>
                        }
                        { this.state.activeCampaignTag != false && 
                            <div className="ediciones-sidebar-list ediciones-sidebar-list-wrap">
                                <div className="p-2 my-2">
                                    <a 
                                        href="#" 
                                        className="boton-inscr-compra-dossier btn btn-primary btn-block btn-rounded-50 font-size-16 form-overlay-button my-4" 
                                        onclick="" 
                                        data-target="solicita-informacion-form-sin-ediciones" 
                                        data-active-campaign={this.state.activeCampaignTag}
                                        data-edicion-id-ig-plus={this.props.cursoIdIgPlus}
                                        data-edicion-id-ig-web={this.props.cursoId}>{this.props.textos.sol_info_txt}</a>
                                </div>
                            </div>
                        }

                        { this.state.cursosInteresantes.hasOwnProperty('cursos_interesantes') && 
                            this.state.cursosInteresantes.cursos_interesantes.length > 0 && 
                            <>
                                <div className="ediciones-sidebar-header white-bg mt-4 w-100 p-2">
                                    <span className="text-lila font-fa-mont-exbold w-100 text-center font-size-30">{this.state.cursosInteresantes.section_title}</span>
                                </div>
                                <div className="ediciones-sidebar-list my-0">
                                    {this.state.cursosInteresantes.cursos_interesantes.map( (cursoItem, index) => 
                                        <div className="edicion-sidebar-lila-item py-2 px-4 mb-0 text-center" key={`curso-interesante-${index}`}>
                                            <a href={cursoItem.link} className="text-lila lila-link">{decodeHTMLEntities(cursoItem.name)}</a>
                                        </div>
                                    )}
                                </div>
                            </>    
                        }    
                    </>    
                }
                
                { !this.state.isLoading && this.state.ediciones != false && 
                    
                    <div className="ediciones-sidebar-list-wrap">
                        <EdicionesSidebarHeader 
                            ubicaciones={this.state.ubicaciones} 
                            selectedUbicacion={this.state.selectedUbicacion} 
                            hanldeSelectUbicaciones={this.hanldeSelectUbicaciones}
                            textos={this.props.textos} />
                        <EdicionesList 
                            textos={this.props.textos}
                            tipo={this.state.tipo}
                            edicionesList={ this.state.selectedUbicacion == null ? this.state.ediciones : this.state.ediciones.filter( edicion => edicion.ubicacion == this.state.selectedUbicacion ) }/>
                    </div> 
                }

            </div>
        )
    }
}