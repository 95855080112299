
import 'owl.carousel';
import React from 'react';
import ReactDOM from 'react-dom';
import SidebarEdicionesComponent from './sidebar_ediciones_component.jsx';

import 'select2';                       // globally assign select2 fn to $ element
// import 'select2/dist/css/select2.css';  // optional if you have css loader

import ValidateFormFields from './module_validation.js';

import 'jquery-validation';

import 'jquery-datetimepicker';

import datepickerFactory from 'jquery-datepicker';
import datepickerJAFactory from 'jquery-datepicker/i18n/jquery.ui.datepicker-es';

import e from 'jquery-datetimepicker';

import {Loader, LoaderOptions} from 'google-maps';


jQuery(document).ready(function(){
    

    $('#hdr-toggler-mob').on('click', function(e){
        
        e.preventDefault();

        if ( $('body').hasClass('show') ) {
            $('#masthead').css({'overflow': 'hidden'});
        }

        $('.top-header-cnt .wrap-widget.widget_search').removeClass('show');
        $('body').toggleClass('show');

        

    });

    $('#search-link-mob').on('click', function(e){
        e.preventDefault();
        if ( !$('body').hasClass('show') ){
            $('#masthead').css({'overflow': 'visible'});
        }else{
            $('#masthead').css({'overflow': 'hidden'});
        }
        
        if ( $('#top-header-mob .widget_search').hasClass('show') ){
            $('#masthead').css({'overflow': 'hidden'});
        }

        $('.top-header-cnt .wrap-widget.widget_search').toggleClass('show');
    });

    
    /* $('#main-nav > .navbar-nav > li.menu-item-has-children > a > i').on('click', function(e){
        
        if ( $(window).width() < 1185 ) {
            e.preventDefault();
            $(this).parent('a').parent('li.menu-item-has-children').children('ul').toggleClass('show');
        }    

        
                
    }) */

    const handleScrollMenuMobile = (menuItemLevel) => {
        setTimeout(function(){

            let submenuHeight = 0;
            
            $(`#${menuItemLevel.id}`).children('ul').children('li').each(function (){
                submenuHeight += $(this).outerHeight();
            });

            if ( submenuHeight > ( window.outerHeight - jQuery('#masthead-mov').outerHeight() - jQuery('#menu-nav-levels').outerHeight() ))  {
                $(`#menu-nav-more-bottom`).addClass('show');
                
            }else{
                $(`#menu-nav-more-bottom`).removeClass('show');
            }
            let insideElementsHeight = 0; 
            
            $(`#${menuItemLevel.id} > ul.show > li`).each( function() { insideElementsHeight+=$(this).outerHeight() } );
            
            $(`#${menuItemLevel.id} > ul.show`).scroll(function(){
                if ( insideElementsHeight - 10 < jQuery(`#${menuItemLevel.id} > ul`).scrollTop() + jQuery(`#${menuItemLevel.id} > ul`).outerHeight() ) {
                    $(`#menu-nav-more-bottom`).removeClass('show');
                }else{
                    $(`#menu-nav-more-bottom`).addClass('show');
                }
            });

        }, 1000);

        return;
    }

    let pilaMenu = [];
    let langSufix = $('html').attr('lang') == 'ca' ? '-ca' : '';

    $('#main-nav > .navbar-nav li.menu-item-has-children').on('click', function(e) {
        /* if ( $(window).width() < 1185 ) {
            e.preventDefault();
            $(this).parent('a').parent('li.menu-item-has-children').children('ul').toggleClass('show');
        } */   

        if (e.target == this){
            // alert( 'clicked on parent element' );
            let menuItemLevel = {};

            menuItemLevel.id = $(this).attr('id');
            menuItemLevel.name = $(this).children('a').text();
            menuItemLevel.link = $(this).children('a').attr('href');
            
            pilaMenu.push({ ...{}, ...menuItemLevel});
            
            
            $('#menu-menu-principal' + langSufix).attr('data-menuleactiveitems', JSON.stringify(pilaMenu));
            
            if ( pilaMenu.length > 0 ) {
                $('#menu-nav-levels').addClass('show');
            }else{
                $('#menu-nav-levels').removeClass('show');
            }

            if ( pilaMenu.length > 1 ) {
                handleScrollMenuMobile(menuItemLevel);
            }

            $('#current-muenu-nav-level').text(menuItemLevel.name);
            $('#current-muenu-nav-level').attr('href', menuItemLevel.link);
            $(`#${menuItemLevel.id}`).children('ul').addClass('show');

        }

    });

    $('#prev-muenu-nav-level').on('click', function(e){
        
        e.preventDefault();

        // console.log(pilaMenu);

        let menuItemLevel = pilaMenu[pilaMenu.length-1];

        $(`#${menuItemLevel.id}`).children('ul').removeClass('show');

        pilaMenu.pop();
        
        $(`#menu-nav-more-bottom`).removeClass('show');

        if ( pilaMenu.length > 0 ) {
            menuItemLevel = pilaMenu[pilaMenu.length-1];
            $('#current-muenu-nav-level').text(menuItemLevel.name);
            $('#current-muenu-nav-level').attr('href', menuItemLevel.link);

            handleScrollMenuMobile(menuItemLevel);
        }else{
            $('#current-muenu-nav-level').text('');
            $('#current-muenu-nav-level').attr('href', '#');
        
            $('#menu-nav-levels').removeClass('show');
            
            
        }

        $('#menu-menu-principal' + langSufix).attr('data-menuleactiveitems', JSON.stringify(pilaMenu));
        
    })

    $('#close-muenu-nav-level').on('click', function(e){
        
        e.preventDefault();

        pilaMenu.forEach( menuItemLevel => {
            $(`#${menuItemLevel.id}`).children('ul').removeClass('show');
        });

        pilaMenu = [];
        
        $('#current-muenu-nav-level').text('');
        $('#current-muenu-nav-level').attr('href', '');

        $('#menu-nav-levels').removeClass('show');

        $('#menu-menu-principal' + langSufix).attr('data-menuleactiveitems', JSON.stringify(pilaMenu));
        $(`#menu-nav-more-bottom`).removeClass('show');
    })


    $('.no-link > a').on( 'click', function(e) {
        e.preventDefault();
    })

    var language = jQuery('html').attr('lang');

    const headers = new Headers({
        'Content-Type': 'application/json',
        'X-WP-Nonce': ajax_var.nonce
    });
    
    
    if ( jQuery(".owl-carousel").length ) {

        jQuery(".owl-carousel").each(function(){
            
            let numberOfItems = $(this).attr("data-numberitems");
            let responObj = {};

            if ( Number.parseInt(numberOfItems) == 2) {
                responObj = {
                    0:{
                        items:1,
                    },
                    540:{
                        items:1,
                    },
                    992:{
                        items:2,
                    },
                    1200:{
                        items:2,
                    }
                }
            } else if ( Number.parseInt(numberOfItems) == 3) {
                responObj = {
                    0:{
                        items:1,
                    },
                    540:{
                        items:1,
                    },
                    992:{
                        items:2,
                    },
                    1200:{
                        items:3,
                    }
                }
            } else if ( Number.parseInt(numberOfItems) == 4) {
                responObj = {
                    0:{
                        items:1,
                    },
                    540:{
                        items:1,
                    },
                    992:{
                        items:2,
                    },
                    1200:{
                        items:4,
                    }
                }
            }
            
            $(this).owlCarousel({
                items: numberOfItems != undefined && numberOfItems != false ? parseInt(numberOfItems) : 1,
                loop:true,
                nav:true,
                dots:true,
                responsiveClass:true,
                responsive:responObj
            });
        
        });

    } 

    if ( $('.parallax-window').length ) {
        /* $('.parallax-window').parallax({
            'position': 'right top'
        }); */
    } 

console.log(igConfig)
console.log(window.igConfig)
    if ( jQuery("#sidebar-ediciones-compra").length ) {
        var sidebarEdicionesTarget = document.getElementById('sidebar-ediciones-compra');
        

        ReactDOM.render( 
            <SidebarEdicionesComponent
                urlProximasEdidiones={ajax_var.urlProximasEdidiones}
                urlCursosTePuedenInteresar={ajax_var.urlTambienTePuedeInteresar}
                headers={headers}
                language={language} 
                cursoId={window.igConfig.curso.id}
                cursoIdIgPlus={window.igConfig.curso.idCursoIGPlus} 
                tipo={jQuery("#grid-sidebar-sidebar-compra").attr('data-tipo')}
                textos={window.igConfig.textosJs}
                />, sidebarEdicionesTarget );
    }

    if ( jQuery("#sidebar-ediciones").length ) {
    
        // var cursoId = jQuery("#sidebar-ediciones").attr('data-curso')
        
        var sidebarEdicionesTarget = document.getElementById('sidebar-ediciones');
        

        ReactDOM.render( 
            <SidebarEdicionesComponent
                urlProximasEdidiones={ajax_var.urlProximasEdidiones} 
                urlCursosTePuedenInteresar={ajax_var.urlTambienTePuedeInteresar}
                headers={headers}
                language={language} 
                cursoId={window.igConfig.curso.id}
                cursoIdIgPlus={window.igConfig.curso.idCursoIGPlus} 
                tipo={jQuery("#grid-sidebar-sidebar").attr('data-tipo')}
                textos={window.igConfig.textosJs}
                />, sidebarEdicionesTarget );


        let targetWrapWidth = 0;

        const fixedwrap = (target_div) => {
            
            let sidebarGriPos = $('body').hasClass('admin-bar') ? 142 : 110;
            let distance = $(target_div).offset().top;
            let dist_left = $('#grid-sidebar-main').offset().left + $('#grid-sidebar-main').width() - $('#grid-sidebar-sidebar').width();
            
            let documentWidth = $(document).width(); //retrieve current document width
            let documentHeight = $(document).height(); //retrieve current document height
            
            let $window = $(window);
    
            $window.scroll(function() {
                
                if ( $window.scrollTop() + sidebarGriPos >= distance ) {
                    // Your div has reached the top
                    $(target_div).addClass("fixed-sidebar").removeClass("absolute-sidebar");
                    fijar_fixed(dist_left);
                }
    
                if ( ( $window.scrollTop() + sidebarGriPos < distance ) && ( $(target_div).hasClass("fixed-sidebar") ) ){
                    $(target_div).removeClass("fixed-sidebar").addClass("absolute-sidebar");
                }
    
                if ( parseFloat($("#site-ftr").offset().top) + parseFloat($("#site-ftr").height()) - parseFloat($window.scrollTop()) < parseFloat($window.height()) ) {
                    $(target_div).addClass("bottom-sidebar");
                } else{ 
                    $(target_div).removeClass("bottom-sidebar");
                }
    
            });
    
            $(window).resize(function() {
                dist_left = $('#grid-sidebar-main').offset().left + $('#grid-sidebar-main').width() - $('#grid-sidebar-sidebar').width();
                if ($(document).width() > 1067){
                    fijar_fixed(dist_left);
                }
            });
    
            const fijar_fixed = (d_left) => {
                $(target_div).css({"left": d_left, "top": sidebarGriPos});
            }
        } 
        
        
        if ( $( window ).width() > 1067 ){
            fixedwrap("#grid-sidebar-sidebar");
        }
    
    
    }

    /*
    if ( $('.form-overlay-button').length ) {

        let formTarget = $('.form-overlay-button').attr('data-target');

        if ( $(`#${formTarget}`).length ) {

            $('.form-overlay-button').on('click', function(e) {
                    
                e.preventDefault();

                $(`#${formTarget}`).fadeIn();

            });

            $('.close-form-overlay').on('click', function(e){
                
                e.preventDefault();

                $(this).closest('.overlay-form-wrap').fadeOut();

            })

        }

    }
    */


    $('body').on('click', '.form-overlay-button', function(e) {
                    
        let formTarget = $(this).attr('data-target');
        
        e.preventDefault();

        $(`#${formTarget}`).show();

        if ( $(this).attr('data-active-campaign') != undefined && $(this).attr('data-active-campaign') != false ) {

            let formInputActiveTags = $(`#${formTarget}`).find('input[name="active-tags"]');
            if ( formInputActiveTags.length > 0 ) {
                formInputActiveTags.attr('value', $(this).attr('data-active-campaign'));
            }           

        }

        if ( $(this).attr('data-edicion-id-ig-plus') != undefined && $(this).attr('data-edicion-id-ig-plus') != false ) {

            let formInputIdIGplus = $(`#${formTarget}`).find('input[name="id-igplus"]');
            if ( formInputIdIGplus.length > 0 ) {
                formInputIdIGplus.attr('value', $(this).attr('data-edicion-id-ig-plus'));
            }           

        }

        if ( $(this).attr('data-solicitar-sesion-tipo') != undefined && $(this).attr('data-solicitar-sesion-tipo') != false ) {

            let formInputIdIGplus = $(`#${formTarget}`).find('input[name="solicitar-sesion-info-de"]');
            if ( formInputIdIGplus.length > 0 ) {
                formInputIdIGplus.attr('value', $(this).attr('data-solicitar-sesion-tipo'));
            }           

        }


       

    });

    $('.close-form-overlay').on('click', function(e){
        
        e.preventDefault();

        // $('#active-tags').attr('value', $(this).attr('data-active-campaign'));
        $(this).closest('.overlay-form-wrap').fadeOut();

    })


    

    $('.js-select2.filter-cursos').each( function(){ 

        $(this).select2({
            placeholder: 'seleccionar'
        });

        let that = $(this);

        $(this).on("change", function (e) { 

            let filtersWrap = that.attr('data-filterswrap') != false && that.attr('data-filterswrap') != undefined  ?  that.attr('data-filterswrap') : false;
            let targetcontainer = that.attr('data-targetcontainer') != false && that.attr('data-targetcontainer') != undefined  ?  that.attr('data-targetcontainer') : false;
            
            let urlToFetch = '';
            let getParams = '';

            if ( filtersWrap != false && targetcontainer != false) {

                let query_params = that.attr('data-query_ajax') != false && that.attr('data-query_ajax') != undefined ? that.attr('data-query_ajax') : false;
                
                if ( query_params != false ) {

                    urlToFetch = query_params;

                    $(`#${filtersWrap} select`).each(function(){
                        getParams += `&${$(this).attr('name')}_id=${$(this).val()}`;
                    })

                    
                }
            }

            if ( urlToFetch != '') {
            
                fetch(`${urlToFetch}${getParams}`, {
                    method: 'get',
                    headers: headers,
                    credentials: 'same-origin'
                })
                .then(response => {
                    return response.ok ? response.json() : 'Not Found...';
                }).then(json_response => {

                    if ( that.attr('data-cursoedicion') != 'undefined' && that.attr('data-cursoedicion') == 'curso' ) {

                        if ( json_response.status == true && json_response.cursos.length > 0 ) {

                            $(`#${targetcontainer}`).empty();
                            let htmlToAppend = getCursosViews(json_response.cursos);

                            $(`#${targetcontainer}`).append(htmlToAppend);
                        }

                    } else {

                        $(`#${targetcontainer}`).empty();
                        let htmlToAppend = getEdicionesViews(json_response);

                        $(`#${targetcontainer}`).append(htmlToAppend);
                    }

                    
                    
                });
            
            }
            
        });

    }); 
    
    $('.js-select2.filter-tipo-combinadas').each( function(){ 

        $(this).select2({
            placeholder: 'seleccionar'
        });

        let that = $(this);

        $(this).on("change", function (e) { 

            let filtersWrap = that.attr('data-filterswrap') != false && that.attr('data-filterswrap') != undefined  ?  that.attr('data-filterswrap') : false;
            let targetcontainer = that.attr('data-targetcontainer') != false && that.attr('data-targetcontainer') != undefined  ?  that.attr('data-targetcontainer') : false;
            let tipologiasList = that.attr('data-tipologiaslist') != false && that.attr('data-tipologiaslist') != undefined  ?  that.attr('data-tipologiaslist') : false;
            let areaId = that.attr('data-areaid') != false && that.attr('data-areaid') != undefined  ?  that.attr('data-areaid') : false;
            
            let urlToFetch = '';
            let getParams = '';

            if ( filtersWrap != false && targetcontainer != false) {

                let query_params = that.attr('data-query_ajax') != false && that.attr('data-query_ajax') != undefined ? that.attr('data-query_ajax') : false;
                
                urlToFetch = `${query_params}/wp-json/cursos_ediciones/cursos_ediciones_tipo_combinada_list?tipologia_id=${tipologiasList}&area_id=${areaId}`;
                    
                if ( query_params != false ) {
                    
                    $(`#${filtersWrap} select`).each(function(){
                        getParams += `&${$(this).attr('name')}_id=${$(this).val()}`;
                    })
                    
                }
            }

            if ( urlToFetch != '') {
            
                fetch(`${urlToFetch}${getParams}`, {
                    method: 'get',
                    headers: headers,
                    credentials: 'same-origin'
                })
                .then(response => {
                    return response.ok ? response.json() : 'Not Found...';
                }).then(json_response => {
                    
                    if ( json_response.status == true ) { 
                        
                        $(`#${targetcontainer}`).empty();
                        
                        let htmlToAppend = '';
                        
                        if ( json_response.itemsToPrintArray.length > 0 ){
                            
                            json_response.itemsToPrintArray.forEach( itemTP => {
                                if ( itemTP.type == 'curso' ){
                                    htmlToAppend += getCursoItemView(itemTP.item);
                                }else{
                                    htmlToAppend += getEdicionItemView(itemTP.item);
                                }
                            });

                        } else {
                            htmlToAppend += `<span class="mt-4 void-mmessage-text">${json_response.void_text}</span>`;
                        }

                        
                        $(`#${targetcontainer}`).append(htmlToAppend);

                        // console.log(htmlToAppend);
                    }
                    
                });
            
            }
            
        });

    }); 

    const getCursoItemView = ( curso ) => {

        let response = '';

        // console.log(curso);
        let modalidadesObject = curso.edicionesPropsList.modalidad;
            
        let modalidades = '';
        let modalidadesString = '';


        if ( modalidadesObject != undefined && modalidadesObject.length > 0 ) {
            Array.from(modalidadesObject).forEach( modalidad => {
                modalidades += `<span class="gotham-bold-14 color-black mr-1">${modalidad.label}</span>`; 
            })
            modalidadesString = `<div class="curso-modalidades">${modalidades}</div>`;
        }

        let ubicacionesObject = curso.edicionesPropsList.ubicacion;
        
        let ubicaciones = '';
        let ubicacionesString = '';

        if ( ubicacionesObject != undefined && ubicacionesObject.length > 0 ) {
            Array.from(ubicacionesObject).forEach( ubicacion => {
                ubicaciones += `<span class="gotham-bold-10 color-lila mr-1">${ubicacion.name}</span>`; 
            })
            ubicacionesString = `${ubicaciones}`;
        }

        let duracion = '';

        if ( curso.duracion.hasOwnProperty('show') && curso.duracion.show == true ) {
            duracion = `<span class="curso-modalidad-item">${curso.duracion.text}</span>`;
        } 
            
        let precio = '';
        if ( curso.precio.show == true ) {
            let precioCurrency = '';
            if ( curso.precio.text != '' ){
                precioCurrency = curso.precio.currency;
            }
            precio = `<span class="precio-curso-item">${curso.precio.text}${curso.precio.currency}</span>`;
        }

        response += `<div class="area-curso-item">
                        <div class="d-flex justify-content-between align-items-center mb-3 w-100">
                            ${modalidadesString}
                            ${duracion}
                        </div>
                        <div class="item-edicion-main-info mb-4">
                            <h3 class="zilab-25">${curso.title}</h3>
                            <div class="d-flex justify-content-start">
                                <div class="area-curso-ubicaciones">${ubicacionesString}</div>                                
                            </div>    
                        </div> 
                        <div class="area-curso-description">${curso.description}</div>
                        <div class="d-flex justify-content-${curso.precio.show == true ? 'between' : 'end'} align-items-end w-100 my-4">
                            ${precio}    
                            <a href="${curso.permalink}" class="px-4 btn btn-primary btn-rounded-50 font-size-16">+ INFO</a>
                        </div>
                    </div>`;    
        
        return response;

    } 
    
    const getCursosViews = (cursos) => {
        
        let response = '';

        cursos.forEach( curso => {
            
            if ( curso.ediciones.length == 0 ) { return; }

            response += getCursoItemView(curso);
        
        });

        return response;

    }

    const getEdicionItemView = (edicion) => {
        
        let response = '';

        let modalidadesString = '';
                
        if ( Object.keys(edicion.modalidad).length > 0 ) {

            modalidadesString = `${edicion.modalidad.label}`;
        }
        
        let duracion = '';
        if ( edicion.duracion.show == true ) {
            duracion = `<span class="gotham-bold-14 color-black">${edicion.duracion.text}</span>`;
        }

        let ubicacionesString = '';

        if ( Object.keys(edicion.ubicacion).length > 0 ) {
            
            ubicacionesString = `${edicion.ubicacion.name}`;
        }
        
        let precio = '';
        if ( edicion.precio.show == true ) {
            let currency = edicion.precio.hasOwnProperty('text') && edicion.precio.text != '' ? edicion.precio.currency : '';
            if ( edicion.precio.hasOwnProperty('text') && edicion.precio.text != '' ) {
                precio = `<div class="precio-matricula-wrap-sidebar">
                            <span class="gotham-book-25 color-lila">${edicion.precio.text} ${currency}</span>
                        </div>`;
            }
        }

        let botonInfo = '';
        if ( edicion.botones.hasOwnProperty('botonInfo') && edicion.botones.botonInfo.show == true ) {
            botonInfo = `<a href="${edicion.botones.botonInfo.url}" class="px-4 btn btn-primary btn-rounded-50 font-size-16 ml-2">${edicion.botones.botonInfo.label}</a>`;
        }

        let botonCompra = '';
        if ( edicion.botones.hasOwnProperty('botonCompra') && edicion.botones.botonCompra.show == true ) {
            botonCompra = `<a href="${edicion.botones.botonCompra.url}" class="px-4 btn btn-primary btn-rounded-50 font-size-16 ml-2">${edicion.botones.botonCompra.label}</a>`;
        }
        /* if ( edicion.botones.hasOwnProperty('botonCompra') && edicion.botones.botonCompra.showMensaje == true ) {
            botonCompra = <span class="mensaje-estado-edicion-ig">{edicion.botones.botonCompra.mensaje}</span>
        } */

        let botonInscribete = '';

        if ( edicion.botones.hasOwnProperty('botonInscribete') && edicion.botones.botonInscribete.show == true ) {
            botonInscribete = `<a href="#" 
                class="px-4 btn btn-primary btn-rounded-50 font-size-16 form-overlay-button ml-2" 
                data-target="${edicion.botones.botonInscribete.targetElement}"
                data-edicion-id-ig-Web="${edicion.botones.botonInscribete.edicionIdIgWeb}"
                data-edicion-id-ig-plus="${edicion.botones.botonInscribete.edicionIdIgPlus}"
                data-active-campaign="${edicion.tagsActiveCampaign}">
                ${edicion.botones.botonInscribete.label}</a>`;
        }


        response += `<div class="area-curso-item pro">
                        <div class="item-edicion w-100">
                            <div class="d-flex justify-content-between align-items-center mb-3 w-100">
                                <span class="gotham-bold-14 color-black">${modalidadesString}</span>
                                ${duracion}
                            </div>    
                            <div class="item-edicion-main-info mb-4">
                                <h3 class="zilab-25">${edicion.title}</h3>
                                <div class="d-flex justify-content-between">
                                    <span class="gotham-bold-10 color-lila">${ubicacionesString}</span>
                                    <span class="zilab-bold-18 color-lila">${edicion.fechaInicioFormated}</span>
                                </div>
                            </div>
                            <div class="gotham-book-14 line-height-22 color-black mb-4">${edicion.description}</div>
                            <div class="d-flex justify-content-${precio != '' ? 'between' : 'end'} align-items-end w-100 my-4">
                                ${precio}    
                                <div class="d-flex justify-content-end align-items-end">    
                                    ${botonInfo}
                                    ${botonCompra}
                                    ${botonInscribete}
                                </div>  
                            </div>
                        </div>    
                    </div>`;
        
        return response;

    }

    const getEdicionesViews = (ediciones) => {
        
        let response = '';

        if ( ediciones == false ){

            response = `<div class="void-cursos-message w-100 my-4">
                            <span class="void-mmessage-text">No hay resultados para el criterio de búsqueda</span>
                        </div>`;

        } else {

            ediciones.forEach( edicion => {
                
                response += getEdicionItemView(edicion);

            });

        }
        

        return response;

    }


    if ( $('.date-time-picker').length ) {


        // Just pass your jquery instance and you're done
        datepickerFactory($);
        datepickerJAFactory($);

        $('.date-time-picker').datetimepicker({
            timepicker:false,
            format:'Y/m/d'
        });
        
    }

    if ( $('.ajax-select').length ) {
        $('.ajax-select').each( function() {
            
            let that = $(this);

            $(this).select2({
                language: {
                    searching: function() {
                        return that.attr('data-searching') != undefined && that.attr('data-searching') != false ? that.attr('data-searching') : 'Buscando...';
                    }
                },
                ajax: {
                    url: that.attr('data-url_ajax'),
                    dataType: 'json',
                    data: function (params) {
                        return {
                          q: params.term, // search term,
                          area_selected: that.attr('id') == 'ubicacionS' ? $('.ajax-select#areaS').val() : ''
                        };
                      },
                    processResults: function (data) {
                        
                        let newResults = [];
                        let newObjtItem = {};

                        $.each(data, function(i, d) {
                            // data.[i]['text'] = d.name;
                            let newObjtItem = {};

                            newObjtItem.id = d.id;
                            newObjtItem.text = d.text.replace(/&amp;/g, "&");
                            
                            newResults.push( newObjtItem );
                          });
                        
                        // console.log(newResults);
                        // console.log(typeof newResults);

                        return {
                            results: newResults
                        };
                    },  
                },
                placeholder: that.attr('data-placeholder')
            });

            if ( $(this).attr('defaultValue') != '' && $(this).attr('defaultText') != '' ) {
            
                var option = new Option($(this).attr('defaulttext'), $(this).attr('defaultvalue'), true, true);
                $(this).append(option).trigger('change');

                // manually trigger the `select2:select` event
                $(this).trigger({
                    type: 'select2:select',
                    params: {
                        data: { id: $(this).attr('defaultvalue'), name: $(this).attr('defaulttext')}
                    }
                });
            }    

        });

        $('#areaS').on('select2:select', function (e) {

            $('#ubicacionS').find('option').remove(); 
            
            fetch(`${$('#ubicacionS').attr('data-url_ajax')}&area_selected=${$('.ajax-select#areaS').val() != '' ? $('.ajax-select#areaS').val() : ''}`, {
                method: 'get',
                headers: headers,
                credentials: 'same-origin'
            }).then(response => {
                return response.ok ? response.json() : 'Not Found...';
            }).then(json_response => {

                let options = '';
                if ( json_response.length > 0 ) {
                    json_response.forEach( (item, index ) => {
                        options += `<option value="${item.id}">${item.text}</option>`;
                    });
                }
                $(`#ubicacionS`).append(options);
                
            });

            $('#ubicacionS').attr('data-url_ajax')
            $('#ubicacionS').select2('destroy').select2(/* {
                language: {
                    searching: function() {
                        return $('#ubicacionS').attr('data-searching') != undefined && $('#ubicacionS').attr('data-searching') != false ? $('#ubicacionS').attr('data-searching') : 'Buscando...';
                    }
                },
                ajax: {
                    url: $('#ubicacionS').attr('data-url_ajax'),
                    dataType: 'json',
                    data: function (params) {
                        return {
                          q: params.term, // search term,
                          area_selected: $('#ubicacionS').attr('id') == 'ubicacionS' ? $('.ajax-select#areaS').val() : ''
                        };
                      },
                    processResults: function (data) {
                        return {
                            results: data
                        };
                    },  
                },
                placeholder: $('#ubicacionS').attr('data-placeholder')
            } */);
        });
    }


    // EQUIPO DOCENTE / STAFF:

    if ( $('#buscador-docentes').length ) {


        $('#limpiar-buscador').on('click', function(e){

            e.preventDefault();
            
            $('#buscador-docentes').find('input').each( function() {
                $(this).val('');
            });

            $('#buscador-docentes').find('select').each( function() {
                
                $(this).attr('value', '');
                $(this).find('option').each( function() {
                    $(this).remove();
                });
            });

        });

        $('#buscador-docentes').on('submit', function(e){
            
            $('#loading-search').fadeIn();
            $('#buscador-results').fadeOut();
            $('#ver-mas-buscador-equipo').fadeOut();
            
            e.preventDefault();

            ( async function(){

                const infoFetch = await getEquipoDocente();
                const equipo = await infoFetch.json();

                console.log(equipo.equipo_r);
                
                if ( equipo.status && equipo.equipo_r.length > 0) {

                    $('#loading-search').fadeOut();
                    $('#buscador-results').empty().append(docentesView(equipo.equipo_r));
                    $('#buscador-results').fadeIn();
                    //if (equipo.equipo_r.length > 39) $('#ver-mas-buscador-equipo').fadeIn();

                    // tenemos que bind las acciones a los botones por que son nuevos del DOM:
                    $(document).on("click", '.ver-cv-equipo', function(e) { 
                        e.preventDefault();
                        let id_persona = $(this).attr("id");
                        open_cv_overlay(id_persona);
                    });

                } else {
                    $('#loading-search').fadeOut();                    
                    $('#buscador-results').empty().append('<span id="no-editions-text" class="no-editions-text-center"><div class="void-cursos-message"><span class="void-mmessage-text">No hay resultados para los criterios de búsqueda.</span></div></span>');
                    $('#buscador-results').fadeIn();
                }

            })()

            return false;

        });

        $('#ver-mas-buscador-equipo').on('click', function(e){
            
            e.preventDefault();
            
            $('#loading-search').fadeIn();
            $('#ver-mas-buscador-equipo').fadeOut();

            ( async function(){

                let offset = $('#buscador-results > div').length;

                const infoFetch = await getEquipoDocente( offset, true );
                const equipo = await infoFetch.json();

                
                if ( equipo.status && equipo.equipo_r.length > 0) {

                    $('#loading-search').fadeOut();

                    $('#buscador-results').append(docentesView(equipo.equipo_r));

                    //if (equipo.equipo_r.length > 39) $('#ver-mas-buscador-equipo').fadeIn();

                    // tenemos que bind las acciones a los botones por que son nuevos del DOM:
                    $('#buscador-results').on("click", '.ver-cv-equipo', function(e) { 
                        e.preventDefault();
                        let id_persona = $(this).attr("id");
                        open_cv_overlay(id_persona);
                    });

                } else {
                    
                    $('#loading-search').fadeOut();
                    $('#ver-mas-buscador-equipo').remove();
                
                }

            })()

        });

        $('.ver-cv-equipo').on('click', function(e){
            
            e.preventDefault();

            let id_persona = $(this).attr("id");

            open_cv_overlay(id_persona);
            
        });

    }

    if ( $('#grid-staff').length ) {

        $('.ver-cv-equipo').on('click', function(e){
            
            e.preventDefault();

            let id_persona = $(this).attr("id");

            open_cv_overlay(id_persona, 'staff');
            
        });


    }

    const open_cv_overlay = (id_persona, section_equipo = 'equipo_docente') => {
        ( async function(){
                const infoFetch = await get_equipo_cv(id_persona, section_equipo);
                const cv_list = await infoFetch.json();

                let html = `
                    <h2 class="sct-tit-blue mb-4">`+cv_list.nombre+`</h2>
                        <div>`;

                if ( cv_list.cv_items.length > 0 ) {
                    cv_list.cv_items.forEach( cv_item => {
                        html += `<div class="ig-listado-item-cnt">`+cv_item+`</div>`;
                    })
                } 

                html += "</div>";

                $('#equipo_cv_overlay').html(html);
                $('#cv_overlay').show();

        })()
    }

    const getEquipoDocente = ( offset = '', esVerMas = false ) => {

        let texto = $('#textoS').val();

        let tipologiaValue = $('#tipologiaS').val();
        let tipologiaId = tipologiaValue == null ? '' : tipologiaValue;

        if ( esVerMas ) {
            let offset = $('#buscador-results > div').length;
        }

        return fetch(`/wp-json/equipo_docente/get_equipo?texto=${texto}&tipologia_id=${tipologiaId}&offset=${offset}`, {
            method: 'get',
            headers: headers,
            credentials: 'same-origin'
        });
    }

    const docentesView = ( docentes ) => {

        let html = '';

        if ( docentes.length > 0 ) {
            docentes.forEach( docente => {
                html += docentesItemView( docente );
            })
        }

        return html;

    }

    const docentesItemView = ( docente ) => {
        let return_html = `
            <div class="item-docente-view-wrap pt-4" id="docente_${docente.ID}">
                <div class="docente-list-img-wrap"><img src="${docente.IMG_url}" /></div>
                <div class="docente-list-tit">${docente.title}</div>
                <div class="docente-cargo">${docente.cargo}</div>
                <div class="docente-cv">`;
            
            var language_web = jQuery('html').attr('lang');
            console.log(language_web);
            if (language_web == 'es-ES'){
                var lang_p = "Ver más";
            } else {
                 var lang_p = "Veure més";
            }

            if (docente.CV_activo == 1) return_html += `<a href="#" id="cv_${docente.ID}" class="ver-cv-equipo rounded-button-blue super-small-wide">`+lang_p+`</a>`;
        return_html += `
                </div>
            </div>`;
        return return_html;
    }

    const get_equipo_cv = ( id_persona, section_equipo = 'equipo_docente' ) => {

        return fetch('/wp-json/equipo_docente/get_equipo_cv?id='+id_persona+'&section='+section_equipo, {
            method: 'get',
            headers: headers,
            credentials: 'same-origin'
        });
    }


    // BUSCADOR ACTIVIDADES:

    if ( $('#buscador-actividades').length ) {
        
        $('#limpira-buscador').on('click', function(e){

            e.preventDefault();
            
            $('#buscador-actividades').find('input').each( function() {
                $(this).val('');
            });

            $('#buscador-actividades').find('select').each( function() {
                
                $(this).attr('value', '');
                $(this).find('option').each( function() {
                    $(this).remove();
                });
            });

        });

        $('#filtrar-buscador').on('click', function(e){
            
            $('#loading-search').fadeIn();
            $('#buscador-results').fadeOut();
            $('#ver-mas-buscador').fadeOut();
            
            e.preventDefault();

            ( async function(){

                const infoFetch = await getEdiciones();
                const ediciones = await infoFetch.json();

                if ( ediciones != false ) {

                    $('#loading-search').fadeOut();
                    $('#buscador-results').empty().append(edicionesView(ediciones));
                    $('#buscador-results').fadeIn();
                    $('#ver-mas-buscador').fadeIn();
            
                } else {
                    $('#loading-search').fadeOut();                    
                    $('#buscador-results').empty().append('<span id="no-editions-text" class="no-editions-text-center"><div class="void-cursos-message"><span class="void-mmessage-text">No hay resultados para los criterios de búsqueda. Apúntate a nuestro newsletter para estar al día cuando se abran nuevas actividades.</span></div></span>');
                    $('#buscador-results').fadeIn();
                }

            })()

        });

        $('#ver-mas-buscador').on('click', function(e){
            
            e.preventDefault();
            
            $('#loading-search').fadeIn();

            ( async function(){

                let offset = $('#buscador-results > div').length;

                const infoFetch = await getEdiciones( offset, true );
                const ediciones = await infoFetch.json();

                
                if ( ediciones != false ) {

                    $('#loading-search').fadeOut();
                    $('#buscador-results').append(edicionesView(ediciones));

                } else {
                    
                    $('#loading-search').fadeOut();
                    $('#ver-mas-buscador').remove();
                
                }

            })()

        });

    }


    const edicionesView = ( ediciones ) => {

        let html = '';

        if ( ediciones.length > 0 ) {
            ediciones.forEach( edicion => {
                html += edicionItemView( edicion );
            })
        }

        return html;

    }


    const edicionItemView = ( edicion ) => {

        let duracion = '';
        if ( edicion.duracion.show == true ) {
            duracion =`<span class="gotham-bold-14 color-black">${edicion.duracion.text}</span>`;
        }

        let precio = '';
        if ( edicion.precio.show == true ) {
            let currency = edicion.precio.text != '' ? edicion.precio.currency : '';
            precio = `<span class="gotham-book-25 color-lila">${edicion.precio.text} ${edicion.precio.currency}</span>`;
        }

        let botonInfo = '';
        if ( edicion.botones.hasOwnProperty('botonInfo') && edicion.botones.botonInfo.show == true ) {
            botonInfo = `<a href="${edicion.botones.botonInfo.url}" class="px-4 btn btn-primary btn-rounded-50 font-size-16 ml-2">${edicion.botones.botonInfo.label}</a>`;
        }

        let botonCompra = '';
        if ( edicion.botones.hasOwnProperty('botonCompra') && edicion.botones.botonCompra.show == true ) {
            botonCompra = `<a href="${edicion.botones.botonCompra.url}" class="px-4 btn btn-primary btn-rounded-50 font-size-16 ml-2">${edicion.botones.botonCompra.label}</a>`;
        }
        /* if ( edicion.botones.hasOwnProperty('botonCompra') && edicion.botones.botonCompra.showMensaje == true ) {
            botonCompra = <span class="mensaje-estado-edicion-ig">{edicion.botones.botonCompra.mensaje}</span>
        } */

        let botonInscribete = '';

        if ( edicion.botones.hasOwnProperty('botonInscribete') && edicion.botones.botonInscribete.show == true ) {
            botonInscribete = `<a href="#" 
                class="px-4 btn btn-primary btn-rounded-50 font-size-16 form-overlay-button ml-2" 
                data-target="${edicion.botones.botonInscribete.targetElement}"
                data-edicion-id-ig-Web="${edicion.botones.botonInscribete.edicionIdIgWeb}"
                data-edicion-id-ig-plus="${edicion.botones.botonInscribete.edicionIdIgPlus}"
                data-active-campaign="${edicion.tagsActiveCampaign}">
                ${edicion.botones.botonInscribete.label}</a>`;
        }

        /* if ( edicion.botones.hasOwnProperty('botonInscribete') && edicion.botones.botonInscribete.showMensaje == true ) {
            botonInscribete = `<span class="mensaje-estado-edicion-ig">${edicion.botones.botonInscribete.mensaje}</a>`;
        } */

        return `<div class="item-edicion-view-wrap pt-4" id="edicion_${edicion.edicionIdIgWeb}">
                    <div class="item-edicion-view-wrap-padd w-100">
                        <div class="item-edicion w-100">
                            <div class="d-flex justify-content-between align-items-center mb-3 w-100">
                                <span class="gotham-bold-14 color-black">${edicion.modalidad.label}</span>
                                ${duracion}
                            </div>
                            <div class="item-edicion-main-info mb-4">
                                <h3 class="zilab-25">${edicion.title}</h3>
                                <div class="d-flex justify-content-between">
                                    <span class="gotham-bold-10 color-lila">${edicion.ubicacion.name}</span>
                                    <span class="zilab-bold-18 color-lila">${edicion.fechaInicioFormated}</span>
                                </div>    
                            </div>        
                            <div class="gotham-book-14 line-height-22 color-black mb-4">
                                ${edicion.description}
                            </div>
                            <div class="d-flex justify-content-${edicion.precio.show == true ? 'between' : 'end'} w-100 my-4">
                                ${precio}
                                <div class="d-flex justify-content-end align-items-end">    
                                    ${botonInfo}
                                    ${botonCompra}
                                    ${botonInscribete}
                                </div>    
                            </div>
                        </div>
                    </div>
                </div>`;
    }

    
    

    const getEdiciones = ( offset = 0, esVerMas = false ) => {


        let texto = $('#textoS').val();
        let desde = $('#desdeS').val();
        let hasta = $('#hastaS').val();

        let areaValue = $('#areaS').val(); 
        let areaId = areaValue == null ? '' : areaValue;
        
        let tipologiaValue = $('#tipologiaS').val();
        let tipologiaId = tipologiaValue == null ? '' : tipologiaValue;

        let ubicacionValue = $('#ubicacionS').val();
        let ubicacionId = ubicacionValue == null ? '' : ubicacionValue;

        let modalidadValue = $('#modalidadS').val();
        let modalidadId = modalidadValue == null ? '' : modalidadValue;

        let formatoValue = $('#formatoS').val();
        let formatoId = formatoValue == null ? '' : formatoValue;

        let exclude_post = [];
        
        if ( esVerMas ) {
            $('#buscador-results > .item-edicion-view-wrap').each(function(){
                let edId = $(this).attr('id').replace('edicion_', '');
                exclude_post.push(edId); 
            });
        }

        let post_to_exclude = exclude_post.join('|');
        // let urlToFetch = '/wp-json/ediciones/proximas_ediciones';  
        
        let ocultar_home = 'false';

        if ( texto == '' && desde == '' && hasta == '' && 
            areaId == '' && tipologiaId == '' && ubicacionId == '' &&
            modalidadId == '' && formatoId == '' ) {
                ocultar_home = 'true';  
        }

        let response = {};

        // return fetch(`/wp-json/ediciones/proximas_ediciones?texto=${texto}&desde=${desde}&hasta=${hasta}&area_id=${areaId}&tipologia_id=${tipologiaId}&formato_id=${formatoId}&modalidad_id=${modalidadId}&ubicacion_id=${ubicacionId}&ocultar_home=${ocultar_home}&offset=${offset}&max_post=10`, {
        return fetch(`/wp-json/ediciones/proximas_ediciones?texto=${texto}&desde=${desde}&hasta=${hasta}&area_id=${areaId}&tipologia_id=${tipologiaId}&formato_id=${formatoId}&modalidad_id=${modalidadId}&ubicacion_id=${ubicacionId}&offset=${offset}&max_post=10&post_to_exclude=${post_to_exclude}&ocultar_home=${ocultar_home}`, {
            method: 'get',
            headers: headers,
            credentials: 'same-origin'
        });
        
    }

    if ( $('#toggle-grid-sidebar').length){
        $('#toggle-grid-sidebar').on('click', function(e){
            
            e.preventDefault();
            
            $('#grid-sidebar-sidebar').toggleClass('show');

        })
        
        $('#grid-sidebar-sidebar').on('click', function(e) {
            if (e.target !== this){
                return;
            } else {
                $('#grid-sidebar-sidebar').toggleClass('show');
            }
        });

    }

    if ( $('#filter-tabs-toggler').length ) {

        $('#filter-tabs-toggler').on('click', function(e){
            
            e.preventDefault();

            $('#filter-tabs').slideToggle();

        })

        $('#filter-tabs a').on('click', function(e){
            
            e.preventDefault();

            if ( $(this).attr('data-gotourl') != undefined && $(this).attr('data-gotourl') != false ) {
                if ( $(this).attr('data-targeturl') != undefined && $(this).attr('data-targeturl') != false ) {
                    if ( $(this).attr('data-gotourl') == 'true' ) {
                        window.location = (`${$(this).attr('data-targeturl')}`);
                    }
                }   
            }

            let tabName = $(this).attr('data-tabname');

            $('#tab-mov-name-cajetin').html(tabName);

            if ( jQuery(window).width() <= 1199 ) {
                $('#filter-tabs').slideUp();
            }    
        })

    }  
    
    if ( $('body.tax-tipologia, body.tax-area_formativa, body.page-template-template-tipologias_combinadas').length ) {

        let hashPage = document.location.hash.replace("/", "");
        if ( $(`#filter-tabs a[href="${hashPage}"]`).length ) {
            $(`#filter-tabs a[href="${hashPage}"]`).trigger('click');
            setTimeout(()=>{
                $(window).scrollTop(0)
            }, 1500);
        }
        $('#filter-tabs a').on('click', function(e){            
            let hash = $(this).attr('href');
            window.location.hash = $(this).attr('href');
        });

    }


    $('.woocommerce-checkout .country-select').select2();
    
    
    if ( $('.form-row.datepicker input').length ) {

        // Just pass your jquery instance and you're done
        datepickerFactory($);
        datepickerJAFactory($);

        $(".form-row.datepicker input").datepicker({
            changeMonth: true,
            changeYear: true,
            yearRange: '1945:2060',
        });
        $.datepicker.regional['es'];
    }

    

    if ( $('#ig-maps').length ) {

        if ( window.hasOwnProperty('igMaps') && window.igMaps.markers ) {


            // or const {Loader} = require('google-maps'); without typescript
            (async function () {

                const options = {/* todo */};
                const apiKey = window.igMaps.hasOwnProperty('apiKey') ? window.igMaps.apiKey : 'AIzaSyBioOr9r6ZWbMPOtZxjRwIpQaa2WQ-I-HE';
                const loader = new Loader(apiKey, options);
                
                const google = await loader.load();

                const locations = JSON.parse(window.igMaps.markers);

                const IgCenter = $(window).width() > 1184 ? { lat: 41.4080036, lng: 2.1019912 } : { lat: locations[0].lat, lng: locations[0].lon };
                const map = new google.maps.Map(document.getElementById("ig-maps"), {
                    zoom: 14,
                    center: IgCenter,
                });

                setMarkers(map, locations);
            
                
                function setMarkers(map, locations) {

                    const image = {
                        url: window.igMaps.markerImg,
                        size: new google.maps.Size(40, 40),
                        /*origin: new google.maps.Point(0, 0),
                        anchor: new google.maps.Point(0, 32), */
                    };
                    
                
                    for (let i = 0; i < locations.length; i++) {
                        
                        let location = locations[i];

                        const infowindow = new google.maps.InfoWindow({
                            content: `<h4 class="text-lila">${location.tit}</h4><p>${location.text}</p>`
                        });
                    
                        const marker = new google.maps.Marker({
                            position: { lat: location.lat, lng: location.lon },
                            map,
                            // icon: image,
                            // title: location[0],
                            // zIndex: beach[3],
                        });
                    
                        marker.addListener("click", () => {
                            infowindow.open({
                                anchor: marker,
                                map,
                                shouldFocus: false,
                            });
                        });
                    }
                }

            })()
            

        }

    }


    if ( $('#validar-user-button').length) {

        jQuery.validator.addMethod("validate_CIF", function(value, element) {
            if ( $('#tipo_doc_ig').val() == '3' ) {
                return true;
            } else {
                return ValidateFormFields.validateDniCifNie(value);
            }            
        }, "Formato incorrecto");

        let validationArgs = {
            'errorClass' : "form-input-error",
            "errorElement": "div",
            rules: {
                "email_ig": {
                    required: true,
                    email: true	
                },
                "nif_ig": {
                    required: true,
                    validate_CIF: true
                }
            },
            messages: {
                "email_ig": {
                    required: "Campo obligatorio",
                    email: "Formato incorrecto"
                },
                "nif_ig": {
                    required: "Campo obligatorio",
                    validate_CIF: "Formato incorrecto"  
                }
            },
            submitHandler: function(form) {
                // some other code
                // maybe disabling submit button
                // then:
                let email = $('#email_ig').val();
                let tipo_doc = $('#tipo_doc_ig').val();
                let nif = $('#nif_ig').val();
            
                let urlToFetch = '/wp-json/user_igp/get_existe_cliente';
                
                $('.billing-message-wrap').fadeOut();

                ( async function(){

                    try {
                        const infoFetch = await fetch(`${urlToFetch}?nif=${nif}&email=${email}&tipo_doc=${tipo_doc}`, {
                            method: 'get',
                            // headers: headers,
                            // credentials: 'same-origin'
                            
                        });

                        const response = await infoFetch.json();

                        const responseObject = JSON.parse(response);
                        

                        if ( responseObject.hasOwnProperty('response') ) {
                            $(`#${responseObject.response}`).fadeIn();
                        }


                    } catch(error) {

                    }    
                })()
                
            }
            
        }
        
        $("#validar-user-on-igplus").validate(validationArgs);

        /* $('#validar-user-button').on('click', function(e){
            
            e.preventDefault();
            
            if ( $("#validar-user-on-igplus").validate(validationArgs) ) {
                alert('pasa');
            } else {
                alert('error');
            }


        }); */

    }


    if ( $('form#logear-user-on-igweb').length ) {

        let validationArgs = {
            'errorClass' : "form-input-error",
            "errorElement": "div",
            rules: {
                "username": {
                    required: true	
                },
                "password": {
                    required: true
                }
            },
            messages: {
                "username": {
                    required: "Campo obligatorio"
                },
                "password": {
                    required: "Campo obligatorio"  
                }
            },
            /* submitHandler: function(form) {
                // some other code
                // maybe disabling submit button
                // then:
                
                
            } */
            
        }
        
        $("form#logear-user-on-igweb").validate(validationArgs);
    }


    if ( $('form.checkout.woocommerce-checkout').length ) {
        
        if ( window.hasOwnProperty('igConfig') && window.igConfig.hasOwnProperty('user') ) {
            
            let urlToFetch = window.igConfig.user.hasOwnProperty('urlToFetch') ? window.igConfig.user.urlToFetch : '/wp-json/user_ig/update_info_user';
            let userIdIGWeb = window.igConfig.user.hasOwnProperty('userIdIGWeb') ? window.igConfig.user.userIdIGWeb : '';

            if ( userIdIGWeb != '' && urlToFetch != '' ) {
        
                ( async function(){

                    const infoFetch = await fetch(`${urlToFetch}?user_id=${userIdIGWeb}`, {
                        method: 'get',
                        headers: headers,
                        credentials: 'same-origin'
                    });

                    const response = await infoFetch.json();

                    const responseObject = JSON.parse(response);
                    let userDataObj = responseObject.userData;

                    for (var [key, value] of Object.entries(JSON.parse(userDataObj))) {
                        
                        let target = $(`#${key}`);

                        if ( target.length ) {

                            if ( key == 'billing_user_fecha_nacimiento' ) {
                                let dateVal = new Date(value);
                                target.attr('value', `${dateVal.getDate()}/${dateVal.getMonth()+1}/${dateVal.getFullYear()}`);
    
                            } else {
                                target.attr('value', value);    
                            }
                            
                        }
                        

                    }
                    


                })()

            }
            

        }

        $('form.checkout.woocommerce-checkout').on('change', '#billing_billing_pais, #billing_user_country', function(e){

            let pais = $(this).val();
            let selectId = $(this).attr('id');

            ( async function(){

                const infoFetch = await fetch(`/wp-json/checkout_ig/specific_fields_update?fieldChangedId=${pais}&fieldChanged=pais&fieldNeededToUpdate=provincia_list`, {
                    method: 'get',
                    /* headers: headers,
                    credentials: 'same-origin' */
                });

                const response = await infoFetch.json();

                const responseObject = JSON.parse(response);
                // console.log(`Provincias list ${JSON.stringify(response)}`)
                // console.log(responseObject)
                let html = '';

                if ( responseObject.status == 'ok' ){

                    for (var [key, value] of Object.entries(responseObject.response)) {
                            
                        html += `<option value="${key}">${value == 'No aplica' ? '' : value}</option>`;

                    }

                    // console.log(selectId);

                    let target = selectId == 'billing_billing_pais' ? 'billing_billing_provincia' : 'billing_user_provincia';

                    $(`#${target} option`).each( function(){
                        $(this).remove();
                    });

                    $(`#${target}`).append(html);

                }
                


            })()

        })

        
    
    }


    if ( $('#billing_user_copiar').length ) {

        $('#billing_user_copiar').val('0');

        $('#billing_user_copiar').on('change', function() {

            if($('#billing_user_copiar').prop('checked') == true) { 
                $('#billing_user_copiar').val('1');
                $('.conditional-hide').toggleClass('hidded');
                setTimeout(
                    function(){
                        $('#billing_first_name').val('');
                        $('#billing_user_nif_cif').val('');
                        $('#billing_city').val('');
                        $('#billing_postcode').val('');
                        
                        $('#billing_address_1').val('');
                        /* $('#billing_billing_pais').val('');
                        $("#billing_billing_provincia option").each(function(){
                            $(this).remove();
                        }); */
                        // $('#billing_billing_provincia').val('');
                        

                        let paisBilling = $('#billing_user_country').val();
                        $('#billing_billing_pais').val(paisBilling);
                        let provinciasOptions = '';

                        $("#billing_user_provincia option").each(function(){

                            let option = $(this).val();
                            let label = $(this).text();

                            provinciasOptions += `<option value="${option}">${option == 'No aplica' ? '' : label}</option>`;
                            
                        });

                        $('#billing_billing_provincia').append(provinciasOptions);

                        let provinciaBilling = $('#billing_user_provincia').val();
                        $('#billing_billing_provincia').val(provinciaBilling);

                        if ( $('#billing_user_country').val() == 1 || $('#billing_user_country').val() == '1' ) {
                            $('#billing_billing_provincia_field').removeClass('hidden-provincia');
                        } else {
                            $('#billing_billing_provincia_field').addClass('hidden-provincia');
                        }
                    }
                , 100);
            }else{
                $('#billing_user_copiar').val('0');
                $('.conditional-hide').toggleClass('hidded');
            }

        });

        /*
        $('#billing_user_first_name, #billing_user_last_name').on('change', function() {     
            if ( $('#billing_user_copiar').val() == 'no' ) {
                let nombreBilling = $('#billing_user_first_name').val();
                let apellidosBilling = $('#billing_user_last_name').val();                
                $('#billing_first_name').val(`${nombreBilling} ${apellidosBilling}`);
            }
        });

        $('#billing_user_documento').on('change', function() {
            if ( $('#billing_user_copiar').val() == 'no' ) {
                let documentoBilling = $('#billing_user_documento').val();
                $('#billing_user_nif_cif').val(documentoBilling);
            }    
        });

        $('#billing_user_poblacion').on('change', function() {
            if ( $('#billing_user_copiar').val() == 'no' ) {
                let poblacionBilling = $('#billing_user_poblacion').val();
                $('#billing_city').val(poblacionBilling);
            }
        });

        $('#billing_user_cp').on('change', function() {
            if ( $('#billing_user_copiar').val() == 'no' ) {
                let cpBilling = $('#billing_user_cp').val();
                $('#billing_postcode').val(cpBilling);
            }
        });

        $('#billing_user_direccion').on('change', function() {
            if ( $('#billing_user_copiar').val() == 'no' ) {
                let direccionBilling = $('#billing_user_direccion').val();
                $('#billing_address_1').val(direccionBilling);
            }
        });

        $('#billing_user_country').on('change', function() {
            if ( $('#billing_user_copiar').val() == 'no' ) {
                let paisBilling = $('#billing_user_country').val();
                $('#billing_billing_pais').val(paisBilling);
            }
        });

        $('#billing_user_provincia').on('change', function() {
            if ( $('#billing_user_copiar').val() == 'no' ) {
            
                $("#billing_billing_provincia option").each(function(){                        
                    $(this).remove();
                });

                let provinciasOptions = '';

                $("#billing_user_provincia option").each(function(){

                    let option = $(this).val();
                    let label = $(this).text();

                    provinciasOptions += `<option value="${option}">${option == 'No aplica' ? '' : label}</option>`;
                    
                });

                $('#billing_billing_provincia').append(provinciasOptions);

                let provinciaBilling = $('#billing_user_provincia').val();
                $('#billing_billing_provincia').val(provinciaBilling);
            }
        });
        
        $('#billing_user_copiar').on('change', function() {
            if ( $('#billing_user_copiar').val() == '' ) {
            
                setTimeout(
                    function(){
                        $('#billing_first_name').val('');
                        $('#billing_user_nif_cif').val('');
                        $('#billing_city').val('');
                        $('#billing_postcode').val('');
                        
                        $('#billing_address_1').val('');
                        $('#billing_billing_pais').val('');
                        $("#billing_billing_provincia option").each(function(){
                            $(this).remove();
                        });
                        $('#billing_billing_provincia').val('');
                    }
                , 500);
                
            }else{
                
                let nombreBilling = $('#billing_user_first_name').val();
                let apellidosBilling = $('#billing_user_last_name').val();

                let newNom = `${nombreBilling} ${apellidosBilling}` != '' ? `${nombreBilling} ${apellidosBilling}` : '_'; 
                $('#billing_first_name').val(`${nombreBilling} ${apellidosBilling}`);

                let documentoBilling = $('#billing_user_documento').val();
                $('#billing_user_nif_cif').val(documentoBilling);

                let poblacionBilling = $('#billing_user_poblacion').val();
                $('#billing_city').val(poblacionBilling);

                let cpBilling = $('#billing_user_cp').val();
                $('#billing_postcode').val(cpBilling);

                let direccionBilling = $('#billing_user_direccion').val();
                $('#billing_address_1').val(direccionBilling);

                let paisBilling = $('#billing_user_country').val();
                $('#billing_billing_pais').val(paisBilling);

                $("#billing_billing_provincia option").each(function(){
                    
                    $(this).remove();

                });

                let provinciasOptions = '';

                $("#billing_user_provincia option").each(function(){

                    let option = $(this).val();
                    let label = $(this).text();

                    provinciasOptions += `<option value="${option}">${option == 'No aplica' ? '' : label}</option>`;
                    
                });

                $('#billing_billing_provincia').append(provinciasOptions);

                let provinciaBilling = $('#billing_user_provincia').val();
                $('#billing_billing_provincia').val(provinciaBilling);

            }
        });

        $('#billing_user_copiar').on('change', function() {
            if ( $('#billing_user_copiar').val() == 'no' ) {
                
                $('#billing_first_name').val('');
                $('#billing_user_nif_cif').val('');

                $('#billing_city').val('');

                let cpBilling = $('#billing_user_cp').val();
                $('#billing_postcode').val(cpBilling);

                let direccionBilling = $('#billing_user_direccion').val();
                $('#billing_address_1').val(direccionBilling);

                let paisBilling = $('#billing_user_country').val();
                $('#billing_billing_pais').val(paisBilling);

                $("#billing_billing_provincia option").each(function(){
                    
                    $(this).remove();

                });

                let provinciasOptions = '';

                $("#billing_user_provincia option").each(function(){

                    let option = $(this).val();
                    let label = $(this).text();

                    provinciasOptions += `<option value="${option}">${option == 'No aplica' ? '' : label}</option>`;
                    
                });

                $('#billing_billing_provincia').append(provinciasOptions);

                let provinciaBilling = $('#billing_user_provincia').val();
                $('#billing_billing_provincia').val(provinciaBilling);

            }
        });
        */

    }

    if ( $('.woocommerce-checkout .woocommerce-billing-fields').length ) {


        $('body').on('blur change', '#billing_user_tipo_doc', function(){
            
            var wrapper = $('#billing_user_documento').closest('.form-row');
            
            if ( $('#billing_user_tipo_doc').val() == '3' ) {
                wrapper.removeClass('woocommerce-invalid').addClass('woocommerce-validated');
                if ( $('#billing_user_documento_field_error').length ) {
                    $('#billing_user_documento_field_error').remove();
                }    
            } else {                
                if ( ValidateFormFields.validateDniCifNie($('#billing_user_documento').val()) ) {
                    wrapper.removeClass('woocommerce-invalid').addClass('woocommerce-validated'); 
                    if ( $('#billing_user_documento_field_error').length ) {
                        $('#billing_user_documento_field_error').remove();
                    }
                } else {
                    wrapper.removeClass('woocommerce-validated').addClass('woocommerce-invalid');
                    if ( ! $('#billing_user_documento_field_error').length ) {
                        $('#billing_user_documento_field').append('<div id="billing_user_documento_field_error" class="ig-chechout-error">Documento no válido</div>')
                    }    
                }
            }
            
        });

        $('body').on('blur change', '#billing_user_documento', function(){
            
            var wrapper = $(this).closest('.form-row');
            
            if ( $('#billing_user_tipo_doc').val() == '3' ) {
                wrapper.removeClass('woocommerce-invalid').addClass('woocommerce-validated');
                if ( $('#billing_user_documento_field_error').length ) {
                    $('#billing_user_documento_field_error').remove();
                }    
            } else {                
                if ( ValidateFormFields.validateDniCifNie($('#billing_user_documento').val()) ) {
                    wrapper.removeClass('woocommerce-invalid').addClass('woocommerce-validated'); 
                    if ( $('#billing_user_documento_field_error').length ) {
                        $('#billing_user_documento_field_error').remove();
                    }
                } else {
                    wrapper.removeClass('woocommerce-validated').addClass('woocommerce-invalid');
                    if ( ! $('#billing_user_documento_field_error').length ) {
                        $('#billing_user_documento_field').append('<div id="billing_user_documento_field_error" class="ig-chechout-error">Documento no válido</div>')
                    }    
                }
            }
            
        });

        /* $('body').on('blur change', '#billing_user_nif_cif', function(){
            
            var wrapper = $(this).closest('.form-row');
            
            if ( ValidateFormFields.validateDniCifNie($('#billing_user_nif_cif').val()) ) {
                wrapper.removeClass('woocommerce-invalid').addClass('woocommerce-validated'); 
                if ( $('#billing_user_nif_cif_field_error').length ) {
                    $('#billing_user_nif_cif_field_error').remove();
                }    
            } else {
                wrapper.removeClass('woocommerce-validated').addClass('woocommerce-invalid');
                if ( ! $('#billing_user_nif_cif_field_error').length ) {
                    $('#billing_user_nif_cif_field').append('<div id="billing_user_nif_cif_field_error" class="ig-chechout-error">Documento no válido</div>')
                }   
            }
        
            
        }); */

        setTimeout( function(){
            $('button#wpmc-next').prop('disabled', true);            
        }, 1000);

        $('body').on('blur change', '.woocommerce-billing-fields__field-wrapper input, .woocommerce-billing-fields__field-wrapper select', function(){    
            
            let that = $(this);
            setTimeout( function(){

                if (
                    !$('input#billing_user_acceptance').is(':checked') ||
                    !$('input#billing_user_formacion').is(':checked') ||
                    ( $('#billing_user_tipo_doc').val() != '3' && !ValidateFormFields.validateDniCifNie($('#billing_user_documento').val() ) )
                    // || ( $('#billing_user_copiar').is(':checked') && !ValidateFormFields.validateDniCifNie($('#billing_user_nif_cif').val()) )
                ) {
                    $('button#wpmc-next').prop('disabled', true);
                } else {
                    $('button#wpmc-next').prop('disabled', false); 
                }

                if ( that.attr('id') == 'billing_user_formacion' ) {
                    var wrapper = that.closest('.form-row');
                    if ($('input#billing_user_formacion').is(':checked')) {
                        wrapper.removeClass('woocommerce-invalid').removeClass('woocommerce-invalid-required-field').addClass('woocommerce-validated'); 
                    } else {
                        wrapper.removeClass('woocommerce-validated').addClass('woocommerce-invalid');
                    }  
                }

            
            }, 200);

            if ( that.attr('id') == 'billing_user_country' ) { 
                if ( that.val() == 1 || that.val() == '1' ) {
                    $('#billing_user_provincia_field').removeClass('hidden-provincia');
                } else {
                    $('#billing_user_provincia_field').addClass('hidden-provincia');
                }    
            }

            if ( that.attr('id') == 'billing_billing_pais' ) {
                if ( that.val() == 1 || that.val() == '1' ) {
                    $('#billing_billing_provincia_field').removeClass('hidden-provincia');
                } else {
                    $('#billing_billing_provincia_field').addClass('hidden-provincia');
                }
            }
            
        });       

        
    }   
    
    
    if ( $('form#validar-user-on-igplus').length ) {

        $('body').on( 'blur change', 'input#nif_ig', function(e){
            
            $(this).val($(this).val().replace(/ /g, ""));
            
        } ); 
        
    }

    if ( $('form.checkout.woocommerce-checkout').length ) {

        $('body').on( 'blur change', 'input#billing_user_documento, input#billing_user_nif_cif', function(e){
            
            $(this).val($(this).val().replace(/ /g, ""));
            
        } ); 
        
    }


    // Header 

	function fixedMenuForScrolledWindow () {

        var $window = $(window);
        
        if ( $window.scrollTop() >= 50 ) {
            
            if ( !($("body").hasClass("scrolled")) ) { 
            
                $("body").addClass("scrolled");
                
            }    

        } else {

            if ( $("body").hasClass("scrolled") ) {
            
                $("body").removeClass("scrolled");
                
            }

                    
        }
    }

    $(document).scroll(function() { 
   
        fixedMenuForScrolledWindow ();

    });

    fixedMenuForScrolledWindow ();


    // INICIO - Quitamos los espacios que quedan entre os elementos del submenu de talleres y formaciones

    let targetSubmenu = `#main-nav > .navbar-nav > li.menu-item-has-children.formaciones > ul.sub-menu > li,
                        #main-nav > .navbar-nav > li.menu-item-has-children.talleres > ul.sub-menu > li`;
    
    $(targetSubmenu).mouseenter( function (e) {

        if ( $(window).outerWidth() >= 1200 ) {
            let that = $(this);
            setTimeout( function(){
                createMosaic(that);
            }, 50);
            
        }

    });

    $(targetSubmenu).mouseleave( function (e) {

        if ( $(window).outerWidth() >= 1200 ) {
            let that = $(this);
            setTimeout( function(){
                deleteMosaic(that);
            }, 50);
        }
            
    });

    const fillBottomGapArray = (numOfChild, hoveredElement) => {

        let bottomGapArray = [];
        let currentChild;
        let currentChildHeight = 0;
        let linkHeight = 0;
        let ulHeight = 0;

        for ( let currentChildIndex = 0; currentChildIndex < numOfChild; currentChildIndex++ ) {

            currentChild = hoveredElement.children('.sub-menu').find('li').eq(currentChildIndex);
            currentChildHeight = currentChild.outerHeight(false);
            linkHeight = currentChild.children('a').outerHeight(false);
            ulHeight = currentChild.children('ul.sub-menu').lenght > 0 ? currentChild.children('ul.sub-menu').outerHeight(false) : 0;

            bottomGapArray.push(currentChildHeight - linkHeight - ulHeight);

            console.log(linkHeight)

        }
        
        console.log(bottomGapArray)
        return bottomGapArray;

    }

    const deleteMosaic = ( hoveredElement ) => {
    
        let numOfChild = hoveredElement.children('.sub-menu').children('li').length;
        let parent = hoveredElement.children('.sub-menu');

        hoveredElement.children('.sub-menu').children('submenu-left').children('li').each( function() {
            jQuery(this).appendTo(parent);
        });
        
        hoveredElement.children('.sub-menu').children('submenu-left').remove();

        hoveredElement.children('.sub-menu').children('submenu-right').children('li').each( function() {
            jQuery(this).appendTo(parent);
        });

        hoveredElement.children('.sub-menu').children('submenu-right').remove();
        
    }

    const fillAllItemsHeight = (numOfChild, hoveredElement) => {

        let currentChild;
        let heightItems = 0;
        let itemsArr = [];
        let elemId;

        for ( let currentChildIndex = 0; currentChildIndex < numOfChild; currentChildIndex++ ) {
            currentChild = hoveredElement.children('.sub-menu').find('li').eq(currentChildIndex);
            heightItems+=currentChild.outerHeight(false);
            elemId = hoveredElement.children('.sub-menu').find('li').eq(currentChildIndex).attr('id');
            
            if ( elemId != undefined && elemId != null && elemId != false ) {
                itemsArr.push(elemId);    
            }else{
                var classList = hoveredElement.children('.sub-menu').find('li').eq(currentChildIndex).attr('class').split(/\s+/);
                $.each(classList, function(index, item) {
                    if (item.indexOf('menu-item-curso-') != -1 ) {
                        hoveredElement.children('.sub-menu').find('li').eq(currentChildIndex).attr('id', item);
                        itemsArr.push(item);
                    }
                });    
            }
            
        }
        // console.log(itemsArr);

        const heightItemsMax = heightItems/2;
        let limitItemIndex=1;

        heightItems = 0;
/*
        for ( let currentChildIndex = 0; currentChildIndex < numOfChild; currentChildIndex++ ) {
            currentChild = hoveredElement.children('.sub-menu').find('li').eq(currentChildIndex);
            heightItems+=currentChild.outerHeight(false);
            if (heightItems>heightItemsMax){
                limitItemIndex = currentChildIndex;
                break;
            }
        }
*/
        var div = Math.floor(numOfChild/2); 
        var rem = numOfChild % 2;



        //console.log(limitItemIndex);

        return {
            itemsArr:itemsArr,
            limitItemIndex:div+rem-1
        };

    }
    
    const createColumns = (itemsColumnsArray, numOfChild, hoveredElement) => {
        
        let subMenuElemnt = hoveredElement.children('.sub-menu');
        let elmId = hoveredElement.attr('id');

        subMenuElemnt.append(`<div id="${elmId}-submenu-left-wrap" class="submenu-left"></div><div id="${elmId}-submenu-right-wrap"  class="submenu-right"></div>`);

        let leftCol = jQuery(`#${elmId}-submenu-left-wrap`);
        let rightCol = jQuery(`#${elmId}-submenu-right-wrap`);
            
        itemsColumnsArray.itemsArr.forEach( ( itemId, index ) => {
            if ( index <= itemsColumnsArray.limitItemIndex ) {
                jQuery(`.${itemId}`).appendTo(leftCol);
            }else{
                jQuery(`.${itemId}`).appendTo(rightCol);
            }
        })

        // .children('.sub-menu')
    }

    const createMosaic = ( hoveredElement ) => {

        let windowWidth = jQuery(window).outerWidth();
        let numOfChild = hoveredElement.children('.sub-menu').children('li').length;
        let numberOfColumns = 2;
        let marginTopArray = [];

        if (numOfChild>numberOfColumns){
            
            let itemsColumnsArray = fillAllItemsHeight(numOfChild, hoveredElement);

            createColumns(itemsColumnsArray, numOfChild, hoveredElement);

            let bottomGapArray = fillBottomGapArray(numOfChild, hoveredElement);
            
            for ( let i=0; i<numOfChild; i++ ) {

                if ( i < numberOfColumns ) {
                    marginTopArray.push(0);
                } else {
                    
                    let gapFromTopElement = bottomGapArray[i-numberOfColumns];
                    let marginTopOfTopElement = marginTopArray[i-numberOfColumns];

                    marginTopArray.push(gapFromTopElement+marginTopOfTopElement);
                }

                hoveredElement.children('.sub-menu').children('li').eq(i).css({'marginTop' : `-${marginTopArray[i]}px`});
            }
            console.log(marginTopArray);
        }
        
        
    }

    // FIN - Quitamos los espacios que quedan entre os elementos del submenu de talleres y formaciones


});