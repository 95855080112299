export default class ValidateFormFields {
    
    static validateIBAN( IBAN = '' ) {
	    if(IBAN == '') return true;
	    
        //Se pasa a Mayusculas
        IBAN = IBAN.toUpperCase();
        //Se quita los blancos de principio y final.
        IBAN = IBAN.trim();
        IBAN = IBAN.replace(/\s/g, ""); //Y se quita los espacios en blanco dentro de la cadena

        var letra1,letra2,num1,num2;
        var isbanaux;
        var numeroSustitucion;
        //La longitud debe ser siempre de 24 caracteres
        if (IBAN.length != 24) {
            return false;
        }

        // Se coge las primeras dos letras y se pasan a números
        letra1 = IBAN.substring(0, 1);
        letra2 = IBAN.substring(1, 2);
        num1 = this.getnumIBAN(letra1);
        num2 = this.getnumIBAN(letra2);
        //Se sustituye las letras por números.
        isbanaux = String(num1) + String(num2) + IBAN.substring(2);
        // Se mueve los 6 primeros caracteres al final de la cadena.
        isbanaux = isbanaux.substring(6) + isbanaux.substring(0,6);

        //Se calcula el resto, llamando a la función modulo97, definida más abajo
        let resto = this.modulo97(isbanaux);
        if (resto == 1){
            return true;
        }else{
            return false;
        }
    }


    static modulo97(iban) {
        var parts = Math.ceil(iban.length/7);
        var remainer = "";

        for (var i = 1; i <= parts; i++) {
            remainer = String(parseFloat(remainer+iban.substr((i-1)*7, 7))%97);
        }

        return remainer;
    }

    static getnumIBAN(letra){
        let ls_letras = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        return ls_letras.search(letra) + 10;
    }


    // Validacion DNI NIF y CIF sacado de 
    // https://gist.github.com/afgomez/5691823
    
    // TAMBIEN PASSPORT, pero solo formato: https://www.codeproject.com/Questions/1046445/How-to-validate-passport-number-using-javascript
    
    static validateDniCifNie( str = '' ) {
        
        const DNI_REGEX = /^(\d{8})([A-Z])$/;
        const CIF_REGEX = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/;
        const NIE_REGEX = /^[XYZ]\d{7,8}[A-Z]$/;
        const PASSPORT_REGEX = /^[A-PR-WY][1-9]\d\s?\d{4}[1-9]$/;

        // Ensure upcase and remove whitespace
        str = str.toUpperCase().replace(/\s/, '');
      
        var valid = false;
        var type = this.spainIdType( str, DNI_REGEX, CIF_REGEX, NIE_REGEX, PASSPORT_REGEX );
        
        switch (type) {
          case 'dni':
            valid = this.validDNI( str );
            break;
          case 'nie':
            valid = this.validNIE( str );
            break;
          case 'cif':
            valid = this.validCIF( str, CIF_REGEX );
            break;
          case 'passport':
            valid = true;
            break; 
        }
    
        const validationObject = {
            type: type,
            valid: valid
        };
        // console.log(`Validation of DNI/NIE/CIF/PASSPORT: ${JSON.stringify(validationObject)}`);
        return validationObject.valid;
        

    }

    static spainIdType( str, DNI_RE, CIF_RE, NIE_RE, PASSPORT_REGEX ) {

        if ( str.match( DNI_RE ) ) return 'dni';
        if ( str.match( CIF_RE ) ) return 'cif';
        if ( str.match( NIE_RE ) ) return 'nie';
        if ( str.match( PASSPORT_REGEX ) ) return 'passport';

    };
    
    static validDNI( dni ) {
        var dni_letters = "TRWAGMYFPDXBNJZSQVHLCKE";
        var letter = dni_letters.charAt( parseInt( dni, 10 ) % 23 );
        
        return letter == dni.charAt(8);
    };
    
    static validNIE( nie ){
    
        // Change the initial letter for the corresponding number and validate as DNI
        var nie_prefix = nie.charAt( 0 );

        switch (nie_prefix) {
            case 'X': nie_prefix = 0; break;
            case 'Y': nie_prefix = 1; break;
            case 'Z': nie_prefix = 2; break;
        }

        return this.validDNI( nie_prefix + nie.substr(1) );

    };
    
    static validCIF( cif, CIF_RE ) {
    
        var match = cif.match( CIF_RE );
        var letter  = match[1],
            number  = match[2],
            control = match[3];
    
        var even_sum = 0;
        var odd_sum = 0;
        var n;
    
        for ( var i = 0; i < number.length; i++) {
          n = parseInt( number[i], 10 );
          if ( i % 2 === 0 ) {
            n *= 2;
            odd_sum += n < 10 ? n : n - 9;
          } else {
            even_sum += n;
          }
        }
    
        var control_digit = (10 - (even_sum + odd_sum).toString().substr(-1) );
        var control_letter = 'JABCDEFGHI'.substr( control_digit, 1 );
    
        // Control must be a digit
        if ( letter.match( /[ABEH]/ ) ) {
          return control == control_digit;
    
        // Control must be a letter
        } else if ( letter.match( /[KPQS]/ ) ) {
          return control == control_letter;
    
        // Can be either
        } else {
          return control == control_digit || control == control_letter;
        }
    
    };
  
    
} 

